.commission-image {
  width: 60%;
}

.fi-device-mobile:before {
  content: "\e967" !important;
}
.fi-map-pin:before {
  content: "\e999" !important;
}
.fi-alarm:before {
  content: "\e923" !important;
}

.banner-hero-image{
  max-height: 100%!important;
 }
 
.navbar .dropdown-toggle::after {
  margin-left: 0.5rem !important;
  content: "\e950" !important;
}
.fi-facebook:before {
  content: "\e905" !important;
}
.fi-twitter:before {
  content: "\e91c" !important;
}
.fi-telegram:before {
  content: "\e919" !important;
}
.fi-messenger:before {
  content: "\e90f" !important;
}

.img-round {
  border-radius: 20px;
}
 .jarallax .jarallax-img {
  /* background: url(theme.css); */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-position: center !important;
  background-size: cover !important;
 
}
.offer-image{
  max-height: 100%!important;

}
.idaho-back {
  background: #000000;
    padding: 0px!important;
    margin-top: 0px!important;
 
}
.lead-generation {
  margin-top: 6%!important;
}

.App {
  text-align: center;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
